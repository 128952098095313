var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.UndoRedoModes = void 0;
  var UndoRedoModes = Object.freeze({
    NONE: "none",
    AUTO: "auto"
  });
  /** @hidden */

  exports.UndoRedoModes = UndoRedoModes;
  return exports;
}