var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MAX_NUM_FIELDS_PER_TABLE = exports.MAX_TABLE_NAME_LENGTH = exports.MAX_FIELD_DESCRIPTION_LENGTH = exports.MAX_FIELD_NAME_LENGTH = void 0;
  // Mirrored from clientServerSharedConfigSettings
  var MAX_FIELD_NAME_LENGTH = 255;
  exports.MAX_FIELD_NAME_LENGTH = MAX_FIELD_NAME_LENGTH;
  var MAX_FIELD_DESCRIPTION_LENGTH = 20000;
  exports.MAX_FIELD_DESCRIPTION_LENGTH = MAX_FIELD_DESCRIPTION_LENGTH;
  var MAX_TABLE_NAME_LENGTH = 255;
  exports.MAX_TABLE_NAME_LENGTH = MAX_TABLE_NAME_LENGTH;
  var MAX_NUM_FIELDS_PER_TABLE = 500;
  exports.MAX_NUM_FIELDS_PER_TABLE = MAX_NUM_FIELDS_PER_TABLE;
  return exports;
}