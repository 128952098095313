var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.BlockRunContextType = void 0;
  // BlockRunContextType, BlockInstallationPageBlockRunContext, ViewBlockRunContext, BlockRunContext
  // must be kept in sync with block_run_context.tsx in hyperbase repo

  /** @hidden */
  var BlockRunContextType;
  /** @hidden */

  exports.BlockRunContextType = BlockRunContextType;
  (function (BlockRunContextType) {
    BlockRunContextType["DASHBOARD_APP"] = "dashboardApp";
    BlockRunContextType["VIEW"] = "view";
  })(BlockRunContextType || (exports.BlockRunContextType = BlockRunContextType = {}));
  return exports;
}